<template>
  <v-container>
      <v-tabs color="#3a4f3f" centered 
      v-model="activeTab" 
        >
          <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact>
            <v-icon
              color="#3a4f3f" class="mr-1">{{ tab.icon }}</v-icon> <span class="name">{{ tab.name }}</span>
          </v-tab>

          <v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route">
            <router-view></router-view>
          </v-tab-item>
      </v-tabs>

 </v-container>
</template>

<script>
import Apis from '@/api/apis'

export default {
  name: 'RegisterInfo',
  created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출");
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
    // console.log("TAB",sessionStorage.getItem("infoTab"))
  },

  data () {
    return {
      activeTab: `/farminfo/registerinfo/company`,
      tabs: [
        { id: 1, name: "경영체", route: `/farminfo/registerinfo/company`, icon: 'mdi-city'},
        { id: 2, name: "농장(사업장)", route: `/farminfo/registerinfo/farm`, icon: 'mdi-satellite' },
        { id: 3, name: "축사(건물)", route: `/farminfo/registerinfo/building`, icon: 'mdi-bank' },
        { id: 4, name: "돈방(구역)", route: `/farminfo/registerinfo/room`, icon: 'mdi-view-dashboard' },
      ],
    }
  },
}
</script>
<style lang="scss" scoped >
.tab_text_green{
  margin-bottom: 3px;
  color:#3a4f3f;
  font-size:14pt !important;
  font-weight: bold !important;
  // background-color: #3a4f3f !important;
}
.tab_text_red{
  color:#88191a;
  font-size:14pt !important;
  font-weight: bold !important;
  // background-color: #3a4f3f !important;
}
.v-tab{
  width:180px !important;
  font-weight:bold !important;
  font-size:16px !important;
  letter-spacing: -0.75px;
}
.img{
  width:25px;
  height:25px;
  // background-color: #3a4f3f;
}
</style>
